import docxtemplater from "docxtemplater";
import JSZip from "jszip";
import JSzipUtils from "jszip-utils";
import saveAs from "file-saver";
import { CertificateTypeUrlTemplateMapper } from "../../constants/certificates";
import { getExtendedData } from "./getExtendedData";
import { getVessel } from "@/apis/vessels";
import { getClient } from "@/apis/clients";
import { getMachineryMakersList } from "@/apis/machineryMakers";

export const populateVesselData = async (vesselId, projectNumber) => {
  if (!vesselId) return;
  const vesselData = await getVessel(vesselId);

  return {
    ...vesselData?.data,
    certificateProjectNumber: projectNumber || "",
  };
};

export const getDocUrl = (docType) => {
  if (!docType) {
    return;
  }
  const templateUrl = CertificateTypeUrlTemplateMapper[docType];
  if (templateUrl) {
    return templateUrl;
  }
  alert(`This template for document download is not awailable yet: ${docType}`);
};

export const getDoc = async (
  vesselId,
  docType,
  projectNumber,
  projectsInvoicingClientList
) => {
  if (vesselId) {
    let invoicingClient;
    let machineryMakersList;
    if (docType === "WOF") {
      const clientId = projectsInvoicingClientList.find(
        (item) => item.projectNumber === projectNumber
      )?.invoicingClientId;
      if (clientId) {
        invoicingClient = await getClient(clientId);
      }
    }
    if (docType === "CoS" || docType === "Tonn") {
      machineryMakersList = await getMachineryMakersList();
    }
    populateVesselData(vesselId, projectNumber).then((response) => {
      createAndSaveDocument(docType, {
        ...response,
        invoicingClient: invoicingClient?.data,
        machineryMakersList: machineryMakersList?.data,
      });
    });
  }
};

export const loadFile = (url, callback) => {
  JSzipUtils.getBinaryContent(url, callback);
};

export const createAndSaveDocument = async (docType, dataset) => {
  /* *
   * The template's path must be passed as an arguement .
   * This path can be either a URL(as  in the commented line) or a path relative to the Public folder
   * */
  const fileUrl = getDocUrl(docType);
  const data = await getExtendedData(dataset);
  const fileName = `${docType}${data.name ? "-" + data.name : ""}${
    data.imo_number ? "-imo-" + data.imo_number : ""
  }`.toLowerCase();

  if (!fileUrl || !data) {
    return;
  }
  loadFile(fileUrl, function (error, content) {
    if (error) {
      throw error;
    }
    let zip = new JSZip(content);
    let doc = new docxtemplater().loadZip(zip);
    doc.setOptions({
      nullGetter: function () {
        return "";
      },
    });
    doc.setData(data);
    try {
      doc.render();
    } catch (error) {
      let e = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        properties: error.properties,
      };
      console.log(JSON.stringify({ error: e }));
      // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
      throw error;
    }
    // docx generating
    let out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    saveAs(out, `${fileName}.docx`); // You can pass this blob to a custom file saver component in the project.
  });
};
