<template>
  <wrapper-page :page-title="details?.name ?? 'Project Details'">
    <template v-slot:MainContentHeaderActions>
      <div class="col-md-6 col-sm-12 text-end">
        <button
          :disabled="isDeleting"
          class="btn btn-sm btn-danger me-3"
          @click="promptDelete"
        >
          Delete
        </button>
        <button
          :class="{ disabled: $v.$anyError }"
          class="btn btn-sm btn-primary"
          @click="saveDetails"
        >
          Update Project
        </button>
      </div>
    </template>

    <template v-if="!isLoadingDetails && details">
      <div class="row">
        <div v-if="isLoadingVesselOptions" class="col-12">
          <i class="text-secondary fa fa-spinner fa-spin me-2"></i>
          Loading vessels ...
        </div>
        <div v-if="!isLoadingVesselOptions" class="col-12">
          <form-input-select
            v-model.number="vesselInput"
            :field="{
              label: 'Vessels',
              name: 'vessel',
              options: vesselOptions,
            }"
            class="col-md-4 me-3 mb-n3"
            @input="(vesselOptions) => addVessel(vesselOptions)"
          />
        </div>
        <div class="col-12 col-sm-6 col-xl-4">
          <div class="table-responsive mt-3">
            <table class="table table-striped table-hover">
              <tbody>
                <tr v-for="(vessel, i) in attachedVessels" :key="vessel.value">
                  <td>
                    <router-link
                      :to="{
                        name: 'vessels-show',
                        params: { vesselId: vessel.value },
                      }"
                      target="_blank"
                      title="Click to go to vessel page"
                    >
                      {{ vessel.text }}
                    </router-link>
                    <i
                      class="ms-2 cursor-pointer fa fa-eye"
                      title="Quick-preview vessel details"
                      @click="previewVesselDetails(vessel.value)"
                    ></i>
                  </td>
                  <td class="text-end">
                    <button
                      class="btn btn-danger btn-sm"
                      title="Remove Vessel"
                      type="button"
                      @click="removeVessel(i)"
                    >
                      <span class="sr-only">Remove Vessel</span>
                      <i class="fa fa-minus"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveDetails">
                <div class="row">
                  <div
                    v-for="field in fields"
                    :key="field.name"
                    class="col-lg-3 col-md-6"
                  >
                    <form-input-select
                      v-if="
                        field.type === 'select' && field.name === 'client_id'
                      "
                      v-model.number="details[field.name]"
                      :error="$v.details[field.name].$error"
                      :error-message="validationMessage($v.details[field.name])"
                      :field="{ ...field, options: relevantClientOptions }"
                      @input="
                        (client_id) => clientSelected(client_id, 'client_id')
                      "
                    />
                    <form-input-select
                      v-else-if="
                        field.type === 'select' &&
                        field.name === 'invoicing_client_id'
                      "
                      v-model.number="details.invoicing_client.id"
                      :error="$v.details.invoicing_client.$error"
                      :error-message="
                        validationMessage($v.details.invoicing_client)
                      "
                      :field="{ ...field, options: relevantClientOptions }"
                      @input="
                        (client_id) =>
                          clientSelected(client_id, 'invoicing_client')
                      "
                    />
                    <form-input-select
                      v-else-if="
                        field.type === 'select' && field.name === 'department'
                      "
                      v-model="details[field.name]"
                      :error="$v.details[field.name].$error"
                      :error-message="validationMessage($v.details[field.name])"
                      :field="field"
                      @input="departmentSelected"
                    />
                    <form-input-select
                      v-else-if="field.type === 'select'"
                      v-model="details[field.name]"
                      :error="$v.details[field.name].$error"
                      :error-message="validationMessage($v.details[field.name])"
                      :field="field"
                    />
                    <multi-select-dropdown
                      v-else-if="field.type === 'multiselect'"
                      v-model="details[field.name]"
                      :error="$v.details[field.name].$error"
                      :error-message="validationMessage($v.details[field.name])"
                      :field="field"
                    />
                    <form-input-date
                      v-else-if="field.type === 'date'"
                      v-model="details[field.name]"
                      :error="$v.details[field.name].$error"
                      :error-message="validationMessage($v.details[field.name])"
                      :field="field"
                    />
                    <form-input-text
                      v-else
                      v-model="details[field.name]"
                      :error="$v.details[field.name].$error"
                      :error-message="validationMessage($v.details[field.name])"
                      :field="field"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div
          class="col-12 d-flex justify-content-start align-items-center mb-3"
        >
          <div class="page-title-box pb-0">
            <h4 class="mb-0">Contacts</h4>
          </div>
          <button
            class="ms-2 btn btn-sm btn-primary"
            title="Add Contact"
            type="button"
            @click="addContact"
          >
            <span class="sr-only">Add Contact</span>
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveDetails">
                <div
                  v-for="(contact, i) in $v.contacts.$each.$iter"
                  :key="`contact-${i}`"
                  class="d-flex mb-2"
                >
                  <div class="row flex-grow-1 d-flex">
                    <div
                      v-for="field in contactFields"
                      :key="`${i}-${field.name}`"
                      class="col-lg-3 col-md-4"
                    >
                      <form-input-text
                        v-model="$v.contacts.$each.$iter[i][field.name].$model"
                        :error="$v.contacts.$each.$iter[i][field.name].$error"
                        :error-message="
                          validationMessage(
                            $v.contacts.$each.$iter[i][field.name]
                          )
                        "
                        :field="field"
                      />
                    </div>
                  </div>
                  <div
                    v-if="contacts.length !== 1"
                    class="ms-4 flex-shrink-0 d-flex"
                  >
                    <button
                      style="margin-top: 29px"
                      class="mb-auto btn btn-danger"
                      title="Remove Contact"
                      type="button"
                      @click="removeContact(i)"
                    >
                      <span class="sr-only">Remove Contact</span>
                      <i class="fa fa-minus"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isLoadingJobOptions" class="row mt-4">
        <div
          class="col-12 d-flex justify-content-start align-items-center mb-3"
        >
          <div class="page-title-box pb-0">
            <h4 class="mb-0">Jobs</h4>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveDetails">
                <div class="d-flex">
                  <div class="row flex-grow-1 d-flex">
                    <div class="col-md-6">
                      <form-input-select
                        v-model="jobCategoryInput"
                        :field="{
                          label: 'Job Category',
                          name: 'job_category',
                          options: jobCategoryOptions,
                        }"
                        @input="jobCategorySelected"
                      />
                    </div>
                    <div class="col-md-6">
                      <form-input-select
                        v-model.number="jobInput"
                        :field="{
                          label: 'Job',
                          name: 'job',
                          options: jobOptionsForCategory,
                        }"
                      />
                    </div>
                  </div>
                  <div class="ms-4 flex-shrink-0 d-flex">
                    <button
                      style="margin-top: 29px"
                      class="mb-auto btn btn-primary"
                      title="Add Job"
                      type="button"
                      @click="addJob"
                    >
                      <span class="sr-only">Add Job</span>
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </form>
              <div v-if="jobs.length" class="mt-2 table-responsive">
                <table class="table table-hover table-custom spacing8">
                  <thead>
                    <tr>
                      <th>Job Category</th>
                      <th>Job</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(job, i) in attachedJobs" :key="job.value">
                      <td>{{ job.category }}</td>
                      <td>{{ job.text }}</td>
                      <td class="text-end">
                        <button
                          class="btn btn-danger btn-sm"
                          title="Remove Job"
                          type="button"
                          @click="removeJob(i)"
                        >
                          <span class="sr-only">Remove Job</span>
                          <i class="fa fa-minus"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div
          class="col-12 d-flex justify-content-start align-items-center mb-3"
        >
          <div class="page-title-box pb-0">
            <h4 class="mb-0">Invoicing</h4>
          </div>
          <button
            class="ms-2 btn btn-sm btn-primary"
            title="Add Invoice"
            type="button"
            @click="addInvoice"
          >
            <span class="sr-only">Add Invoice</span>
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div
                  v-for="(invoice, i) in invoices"
                  :key="`invoice-${i}`"
                  :class="`d-flex ${
                    invoices.length > 1 && i < invoices.length - 1
                      ? 'border-bottom pb-3 mb-4'
                      : ''
                  }`"
                >
                  <invoice-editor
                    ref="invoiceEditor"
                    :invoice="invoice"
                    :parrentIndex="`invoiceEditor${i}`"
                  />
                  <div class="ms-4 flex-shrink-0 d-flex">
                    <button
                      style="margin-top: 29px"
                      class="mb-auto btn btn-danger"
                      title="Remove Invoice"
                      type="button"
                      @click="removeInvoice(i)"
                    >
                      <span class="sr-only">Remove Invoice</span>
                      <i class="fa fa-minus"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
  </wrapper-page>
</template>

<script>
import { deleteProject, getProject, updateProject } from "@/apis/projects";
import WrapperPage from "../../components/layout/WrapperPage.vue";
import FormInputText from "../../components/ui/FormInputText.vue";
import FormInputDate from "../../components/ui/FormInputText.vue";
import FormInputSelect from "@/components/ui/FormInputSelect";
import moment from "moment";
import { DepartmentOptions } from "@/constants/projects";
import {
  ProjectContactFields,
  ProjectFields,
} from "@/constants/project_fields";
import jobsMixin from "../../mixins/projects/jobs";
import clientsMixin from "../../mixins/projects/clients";
import vesselsMixin from "../../mixins/projects/vessels";
import responsiblesMixin from "../../mixins/projects/users";
import { nullToDoubleDash } from "@/helpers/generic";
import { getClientsForVessel } from "@/apis/clients";
import {
  notifyChangesSaved,
  notifyError,
  notifySuccess,
} from "@/helpers/notification";
import ConfirmModal from "@/components/ConfirmModal";
import { email, required } from "vuelidate/lib/validators";
import { validationMessage } from "@/helpers/validations";
import VesselQuickViewModal from "@/components/vessels/VesselQuickViewModal";
import { getVessel } from "@/apis/vessels";
import MultiSelectDropdown from "../../components/ui/MultiSelectDropdown.vue";
import InvoiceEditor from "../../components/projects/InvoiceEditor.vue";
import { confirmRedirect } from "../../helpers/generic";
import { store } from "../../main";
import _ from "lodash";

export default {
  name: "ProjectsShow",
  mixins: [jobsMixin, clientsMixin, vesselsMixin, responsiblesMixin],
  components: {
    FormInputSelect,
    WrapperPage,
    FormInputText,
    FormInputDate,
    MultiSelectDropdown,
    InvoiceEditor,
  },
  data() {
    return {
      formChanged: false,
      isLoadingDetails: false,
      isDeleting: false,
      isSavingDetails: false,
      details: null,
      contacts: [{ type: null, name: null, phone: null, email: null }],
      invoices: [],
      fields: ProjectFields,
      contactFields: ProjectContactFields,
    };
  },
  validations: {
    details: {
      project_number: {},
      name: {
        required,
      },
      department: {
        required,
      },
      category: {
        string: (value) => typeof value === "string" || value instanceof String,
      },
      status: {},
      date_assigned: {},
      target_completion_date: {},
      date_closed: {},
      client_id: {},
      invoicing_client: {},
      invoicing_status: {},
      comments: {},
      responsibles: {},
    },
    contacts: {
      $each: {
        type: {},
        name: {
          required,
        },
        phone: {},
        email: {
          email,
        },
      },
    },
  },
  methods: {
    stateIsNotChanged() {
      const defaultDetails = store.state.dafultFormState?.details;
      const defaultInvoices = store.state.dafultFormState?.invoices;
      const defaultContacts = store.state.dafultFormState?.contacts;
      const defaultJobs = store.state.dafultFormState?.jobs;
      const defaultVessels = store.state.dafultFormState?.vessels;

      const result =
        _.isEqual(this.details, defaultDetails) &&
        _.isEqual(this.invoices, defaultInvoices) &&
        _.isEqual(this.contacts, defaultContacts) &&
        _.isEqual(this.jobs, defaultJobs) &&
        _.isEqual(this.vessels, defaultVessels);
      return result;
    },
    validationMessage,
    proposeNewName(name) {
      if (!this.details?.name) this.details.name = name;
    },
    addContact() {
      this.contacts.push({ type: null, name: null, phone: null, email: null });
    },
    removeContact(index) {
      if (this.contacts.length === 1) return;

      this.contacts.splice(index, 1);
    },
    addInvoice() {
      this.invoices.push({
        invoice_number: "",
        invoice_date: null,
        due_date: null,
        paid_on: null,
        gross_amount: null,
        tax_amount: null,
        notes: null,
      });
    },
    removeInvoice(index) {
      this.invoices.splice(index, 1);
    },
    departmentSelected(department_id, category_id) {
      const categoryFieldIndex = this.fields.findIndex(
        (f) => f.name === "category"
      );

      if (!department_id) {
        this.$set(this.fields[categoryFieldIndex], "options", []);
        return;
      }

      const department = DepartmentOptions.find(
        (department) => department.value === department_id
      );

      this.$set(
        this.fields[categoryFieldIndex],
        "options",
        department.categories
      );

      if (category_id) this.$set(this.details, "category", category_id);
      else if (department.categories.length)
        this.$set(this.details, "category", department.categories[0]?.value);
      else this.$set(this.details, "category", "");
    },
    async saveDetails() {
      this.$v.$touch();
      const invalidInvoices =
        this.$refs.invoiceEditor &&
        this.$refs.invoiceEditor.some((ie) => ie.isInvalid());
      if (this.$v.$anyError || invalidInvoices) {
        notifyError(this, "not all required fields have been entered");
        return;
      }

      this.isSavingDetails = true;

      const details = {
        ...this.details,
        jobs: this.jobs,
        vessels: this.vessels,
      };

      if (details?.responsibles) {
        details.responsibles = details.responsibles?.map((elem) => elem?.value);
      }

      if (!details?.invoicing_client || !details?.invoicing_client.id)
        delete details?.invoicing_client;

      if (this.contacts && this.contacts.length && this.contacts[0]?.name)
        details.contacts = this.contacts;

      if (this.invoices && this.invoices.length)
        details.invoices = this.invoices;

      // First upload all documents.
      const invoiceEditor = this.$refs.invoiceEditor;
      if (invoiceEditor) {
        for (let index = 0; index < invoiceEditor.length; index++) {
          const editorRef = invoiceEditor[index];
          await editorRef.uploadEventDocuments();
        }
      }

      // Then save the project.
      try {
        await updateProject(this.$route.params.projectId, details)
          .then(() => {
            notifyChangesSaved(this);
          })
          .catch((err) => {
            notifyError(this, err?.response?.data);
            throw err;
          });
      } finally {
        this.isSavingDetails = false;
      }
      // to reload the page and re-retrieve information
      this.getDetails();
    },
    async getDetails() {
      let details = null;

      this.isLoadingDetails = true;
      await getProject(this.$route.params.projectId)
        .then(({ data }) => {
          details = nullToDoubleDash(data, ["name"]);
          delete details.client_name;
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
          this.isLoadingDetails = false;
        });

      if (!details) return;

      if (
        details.contacts &&
        details.contacts.constructor === Array &&
        details.contacts.length > 0
      ) {
        this.contacts = [...details.contacts];
        delete details.contacts;
      }

      if (
        details.invoices &&
        details.invoices.constructor === Array &&
        details.invoices.length > 0
      ) {
        this.invoices = [...details.invoices];
        delete details.invoices;
        if (this.invoices) {
          this.invoices.forEach((invoice) => {
            if (invoice.invoice_date)
              invoice.invoice_date = moment(invoice.invoice_date).format(
                "YYYY-MM-DD"
              );
            if (invoice.due_date)
              invoice.due_date = moment(invoice.due_date).format("YYYY-MM-DD");
            if (invoice.paid_on)
              invoice.paid_on = moment(invoice.paid_on).format("YYYY-MM-DD");
          });
        }
      }

      if (
        details.jobs &&
        details.jobs.constructor === Array &&
        details.jobs.length > 0
      ) {
        this.jobs = [...details.jobs];
        delete details.jobs;
      }

      if (
        details.vessels &&
        details.vessels.constructor === Array &&
        details.vessels.length > 0
      ) {
        this.vessels = [...details.vessels];
        delete details.vessels;

        if (this.vessels.length)
          getClientsForVessel(this.vessels[0])
            .then(({ data }) => {
              this.vessel_clients = data;
            })
            .catch((err) => {
              notifyError(this, err?.response?.data);
            });
      }

      if (
        details.responsibles &&
        details.responsibles.constructor === Array &&
        details.responsibles.length > 0 &&
        this.usersOptions.length
      ) {
        details.responsibles = details.responsibles.map((respId) =>
          this.usersOptions.find((opt) => opt.value == respId)
        );
      }

      if (details.date_assigned)
        details.date_assigned = moment(details.date_assigned).format(
          "YYYY-MM-DD"
        );
      if (details.target_completion_date)
        details.target_completion_date = moment(
          details.target_completion_date
        ).format("YYYY-MM-DD");
      if (details.date_closed)
        details.date_closed = moment(details.date_closed).format("YYYY-MM-DD");

      if (!details.invoicing_client) details.invoicing_client = {};

      this.details = details;

      this.departmentSelected(this.details.department, this.details.category);
      this.formChanged = false;
      this.isLoadingDetails = false;
    },
    async previewVesselDetails(vesselId) {
      await getVessel(vesselId)
        .then(({ data: vessel }) => {
          this.$modal.show(
            VesselQuickViewModal,
            { vessel },
            {
              name: "vessel-preview-modal",
              height: "auto",
              width: "300px",
            }
          );
        })
        .catch((err) => {
          notifyError(this, err?.response?.data);
        });
    },
    updateFormChanged() {
      if (!this.formChanged) {
        this.formChanged = true;
        return;
      }
      if (this.stateIsNotChanged()) {
        this.formChanged = false;
      }
    },
    async promptDelete() {
      if (this.isDeleting) return;

      this.isDeleting = false;

      this.$modal.show(
        ConfirmModal,
        {},
        {
          name: "confirm-modal",
          height: "auto",
          width: "300px",
        },
        {
          "before-close": async (e) => {
            if (e.params !== true) return;

            await deleteProject(this.$route.params.projectId)
              .then(() => {
                notifySuccess(this, "Project has been deleted");
                this.$router.push({ name: "projects-index" });
              })
              .catch((err) => {
                notifyError(this, err?.response?.data);
              });
            this.isDeleting = false;
          },
        }
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.formChanged) {
      confirmRedirect(next);
    } else {
      next();
    }
  },
  mounted() {
    this.getDetails().then(() => {
      store.commit("storeDefaultFormState", {
        invoices: this.invoices,
        details: this.details,
        contacts: this.contacts,
        jobs: this.jobs,
        vessels: this.vessels,
      });
      this.updateFormChanged();
    });
  },
  watch: {
    vessels: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
    jobs: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
    details: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
    invoices: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
    contacts: {
      handler() {
        this.updateFormChanged();
      },
      deep: true,
    },
    usersOptions() {
      const responsiblesFieldIndex = this.fields.findIndex(
        (field) => field.name === "responsibles"
      );

      if (responsiblesFieldIndex >= 0) {
        this.$set(
          this.fields[responsiblesFieldIndex],
          "options",
          this.usersOptions
        );

        if (
          this.details?.responsibles &&
          this.details.responsibles.constructor === Array &&
          this.details.responsibles.length > 0
        ) {
          this.details.responsibles = this.details.responsibles.map((respId) =>
            this.usersOptions.find((opt) => opt.value == respId)
          );
        }
      }
    },
  },
};
</script>
